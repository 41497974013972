<template>
  <div class="coupon-pool-log">
    <div class="head">
      <span class="title">쿠폰 사용 현황</span>
    </div>
    <div class="body">
      <div class="table-responsive" v-if="state.log.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>포인트</th>
            <th>등록일</th>
            <th>이용 내역</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="l in state.log" :key="l.id">
            <td>{{ l.useDesc }}</td>
            <td>{{ l.regiDate }}</td>
            <td>
              <span>{{ l.teller === "NEW" ? "+" : "-" }}</span>
              <span>{{ lib.getNumberFormat(l.pointPay) }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-center">
              <span>합계</span>
            </td>
            <td>
              <span>{{ lib.getNumberFormat(sum()) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import lib from "../scripts/lib";
import NoData from "@/components/NoData.vue";

function Component(initialize) {
  this.name = "modalCouponPoolLog";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData},
  computed: {
    lib() {
      return lib;
    }
  },
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
      state.loaded = false;
      http.get(`/api/admin/coupons/pools/${modalParams.couponNo}/log`).then(({data}) => {
        state.loaded = true;
        state.log = data.body;
      });
    });

    const state = reactive({
      loaded: false,
      log: [],
    });

    const modalParams = store.getters.modalParams(component);

    const sum = () => {
      return state.log.reduce((arr, cur) => {
        return cur.teller === "NEW" ? arr + cur.pointPay : arr - cur.pointPay;
      }, 0);
    };
    return {component, state, modalParams, sum};
  }
});
</script>

<style lang="scss" scoped>
.coupon-pool-log {
  padding: $px25;
  background: #fff;

  .head {
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }
  }

  .body {
    font-size: $px14;
  }
}
</style>e